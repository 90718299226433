<template>
    <div>   
            <br>
            <b-card
                header="報名表欄位設定"
                border-variant="light"
                header-border-variant="light"
                header-bg-variant="light"
                align="center"   
                class="card-3"         
                >

            <b-card-body style="width:980px;" class="overflow-auto" id="style-3">
            <b-card-text > 
            <div v-if="JSON.stringify(obj)==='{}'">
            <a-alert v-if="formdata" message="資料填妥, 確認無誤進行下一步" type="success" /> 
            </div>
           <div class="row" >
                <div class="col-6" style="border:0px #ccc solid;border-radius:8px;padding:5px;">                    
                    <h5>您的報名表</h5>
                    <draggable
                        class="dragArea list-group"
                        :list="list1"
                        group="people"
                        @change="log"
                        >
                        <div class="list-group-item" v-for="element in list1" :key="element.id"> 
                            <div class="form-group" v-if="element.input=='text' || element.input=='email' || element.input=='tel' || element.input=='date' || element.input=='file' ">
                                <label for="idexample">{{ element.name }}<a-icon type="delete" v-if="reqs(element.id)" @click="deletes(element.id)" /></label> 
                                <input v-model="element.val"  :type="element.input" class="form-control" id="idexample" :required="element.required" aria-describedby="emailHelp" :placeholder="element.placeholder">
                            </div> 
                            <div class="form-group" v-if="element.input=='radio' || element.input=='checkbox'">
                                <label for="idexample">{{ element.name }}<a-icon type="delete" @click="deletes(element.id)" /></label>
                                <br />
                                    <a-row type="flex" >
                                        <a-col :span="5" :offset="1" v-for="obj in element.list" :key="obj.value">
                                                <div >       
                                                    <span> 
                                                        <input v-if="element.input=='radio'" v-model="element.val" name="customRadioInline1" :type="element.input" :value="obj.value"/>
                                                        <input v-if="element.input=='checkbox'" v-model="element.ary[obj.value]" :name="element.id" :type="element.input" :value="obj.value"/> 
                                                    : {{ obj.text }} 
                                                    </span>
                                                </div>                            
                                        </a-col>                  
                                    </a-row>                                
                            </div> 
                            <div class="form-group" v-if="element.input=='select'">
                                <label for="idexample">{{ element.name }} <a-icon type="delete" @click="deletes(element.id)" /></label>
                                <br />
                                <select v-model="element.val" class="custom-select">
                                    <option selected>--請選擇---</option>
                                    <option v-for="obj in element.list" :key="obj.value" :value="obj.value">{{obj.text}}</option>
                                </select>   
                            </div>                                                        
                        </div>
                    </draggable>
                </div>  

                <div class="col-6" style="border:0px #ccc solid;border-radius:8px;padding:5px;">
                    <h5>拖拉欄位組件</h5>
                    <draggable
                        class="dragArea list-group"
                        :list="list2"
                        :group="{ name: 'people', pull: 'clone', put: false }"
                        :clone="cloneDog"
                        @change="log"
                        >
                        <div class="list-group-item" v-for="element in list2" :key="element.id" >
                         <b-icon icon="arrows-move" scale="1" variant="Secondary"></b-icon>
                         {{ element.name }}
                        </div>
                    </draggable>
                </div>            

            </div>  
            </b-card-text>
            </b-card-body>
            </b-card>
            <br>
            <div class="sava" v-if="JSON.stringify(obj)!=='{}'" >  
                 <a-button type="primary" shape="round" icon="save" @click="upsave" size="large" :disabled="!formdata" :loading="loading" block>
                     存檔變更                              
                 </a-button>
            </div>              
    </div>
</template>
<script>

import Formdatas from '../assets/json/formdata.json'
import draggable from "vuedraggable";
//let idGlobal = 8;
export default {
    name: 'Mform',
    order: 3,
    components : {
        draggable
    },
    props:{
          page:{
              type:Number
          },
          obj:{ 
              type:Object
          },  
          cobj:{ 
            type:Object
          },                    
        },    
    data(){
    return {
        loading:false,
        myJson: Formdatas, 
        list1: [],
        list2: [],
        form:{},      
        }; 
    },
    watch: {
        cobj() {
        if (this.cobj !== undefined) {
            if (this.cobj.id > 0) {
            this.onupdate();
            }
        }
        },
    },      
    computed:{
      formdata(){    
          if(this.list1.length > 0){
            let rs={
                pages: 7,
                state:true,
                data: this.list1
            }     
            if(JSON.stringify(this.obj)==='{}'){          
                this.$emit('done',rs)                            
            }
            return true
          }else{
            let rs={
                pages: 7,
                state:false,
                data: null
            } 
            if(JSON.stringify(this.obj)==='{}'){                            
                this.$emit('done',rs)  
            }               
              return false
          }
      },
    },
    methods :{
        deletes(val){
            let self = this
            this.$confirm({
                title: '刪除後無法再編修!',
                content: '',
                okText: '送出',
                cancelText: '取消',
                async onOk() {
                (async () => {  
                    setTimeout(() => {   
                    self.remove(val)  
                    clearInterval();
                    }, 800); 
                })();
                },
                    onCancel() {},
                });              
        },

        reqs(id){
            let list = [1,2]
            let rs =list.filter((el)=> { return el === id})
            return rs.length >0? false:true
        },

        remove(ids){
            let id = this.list1.map(x =>x.id).indexOf(ids);                        
            this.list1.splice(id,1)  
        },
        toggleClass(val){
            this.mydata=this.mydata.map(x=> { 
                        x.expanded = false
                        if(x.value === val){
                            x.expanded = !x.expanded
                        }
                        return x;
                        } );
            let idata = this.mydata.filter((el)=> { return el.value === val})
            let rs={
                pages: 7,
                state:true,
                data: idata[0]
            }    
                                
            this.$emit('done',rs)                                              
        },        

        log(evt) {
            window.console.log(evt);
            },
        cloneDog({ id }) {
            let rs=this.list2.filter((el)=> { return el.id === id})
            //console.log(rs[0].name)            
            return {
                //id: idGlobal++,
                id:id,
                name: rs[0].name,
                input : rs[0].input,
                required : rs[0].required,
                placeholder : rs[0].placeholder,
                list : rs[0].list,
                val : "",
                ary : {}
            };
        },
        upsave(){
            this.loading = true  
            let tmp=[];
            for(var i in this.list1){
                tmp.push({"oid":this.list1[i].id});
            }                
            this.$emit('updage',5,tmp)
        }, 
        updone(){
            this.loading = false
        },
        
        inidata(){
            if(JSON.stringify(this.obj)!=='{}'){ 
                this.list1 =[]
                for(var i in this.obj.options){
                    let tmp=this.myJson.filter((el)=>{return el.id ===this.obj.options[i].oid})
                    this.list1.push(tmp[0])
                }                
            }
        } ,
        
        async onupdate(){          
          const self = this;
          setTimeout(() => {
                if(JSON.stringify(self.cobj)!=='{}'){   
                    self.list1 =[]
                    for(var i in self.cobj.options){
                        let tmp=self.myJson.filter((el)=>{return el.id ===self.cobj.options[i].oid})
                        self.list1.push(tmp[0])
                    }                    
                    let rs={
                        pages: 7,
                        state:true,
                        data: self.list1
                    }  
                    self.$emit('done',rs)              
                } 
            }, "1000");
        },        
    },
    mounted () {
        this.list1 =this.myJson.filter((el)=>{return el.id <=2})
        this.list2 =this.myJson.filter((el)=>{return el.id >2})
        this.inidata()
    },
    
}
</script>
<style scoped>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}

.ddiv{
    padding:7px;
    border-radius: 17px;
    margin: 5px auto;
    box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
	transition: all .3s ease;
}
.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.sava {
  position: fixed;
  right: 0;
  top: 35%;
  width: 8em;
  margin-top: -2.5em;   
}
</style>