<template>
    <div>   
            <br>
            <b-card
                header="開始建立活動內容！"
                header-border-variant="light"
                header-bg-variant="light"
                align="center"
                class="card-3"            
                >
               
            <b-card-body style="width:980px;padding:5px 0px 5px 0px;" class="overflow-auto">
            <b-card-text> 
                <a-alert v-if="errors!==''" :message="errors" :type="mtyps" show-icon /> 
                <div v-if="JSON.stringify(obj)==='{}'">
                <a-alert v-if="formdata" message="資料填妥, 確認無誤進行下一步" type="success" /> 
                </div>
            <div class="text-left" style="padding:5px 50px 5px 50px;">
            <b-form @submit="onSubmit" @reset="onReset">  

                <p class="h6"><b-icon-pencil-square></b-icon-pencil-square> 活動簡介</p> 
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">
                <div ref="editortools" id="home" class="div"></div> 
                <p class="h6"><b-icon-exclamation-circle></b-icon-exclamation-circle> 注意事項</p> 
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;"> 
                <b-form-group id="input-group-2" style="width:100%" label="注意事項*  " label-for="input-2">
                    <b-form-textarea
                        id="input-2"
                        v-model="form.desc"
                        type="text"
                        placeholder="輸入注意事項 "
                        rows="8"
                        autocomplete="off"
                        :state="descState"                        
                        required
                    ></b-form-textarea>
                </b-form-group>    

            </b-form>   
            </div>

                            
            </b-card-text>
            </b-card-body>
            </b-card>

            <br/>
            <div class="sava" v-if="JSON.stringify(obj)!=='{}'" >  
                 <a-button type="primary" shape="round" icon="save" @click="upsave" size="large" :disabled="!formdata" :loading="loading" block>
                     存檔變更                              
                 </a-button>
            </div>    

        <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">
              <b-spinner  variant="primary" label="Text Centered"></b-spinner>
              <strong >Loading....</strong>
          </div>
        </b-modal> 
            
    </div>
</template>

<script>
import {mapState} from 'vuex';
import E from "wangeditor";

export default {
    name: 'Macdecs',    
    props:{
          page:{
              type:Number
          },
          obj:{ 
              type:Object
          },
          cobj:{ 
            type:Object
          },                      
        },    
    data(){
        return {
            loading:false,
            errors:'',
            mtyps:'error',
            uploadurl: process.env.VUE_APP_URL +'user/edimage', 
            editor: null,
            editorData: '',      
            form: {
                 desc : '',            
                },                                    
                
        }
    },
    watch: {
        cobj() {
        if (this.cobj !== undefined) {
            if (this.cobj.id > 0) {
            this.onupdate();
            }
        }
        },
    },    
    computed:{
      ...mapState('account',['status','user']),
    
          
      formdata(){    
          if(this.form.desc!=='' && this.editorData.length>0){
            let rs={
                pages: 5,
                state:true,
                data: {
                        desc : this.form.desc,
                        content : this.editorData,
                    },
            }             
            if(JSON.stringify(this.obj)==='{}'){   
                this.$emit('done',rs)              
            }
            return true
          }else{  
              
            let rs={
                pages: 5,
                state:false,
                data:null,
            }   
            if(JSON.stringify(this.obj)==='{}'){               
              this.$emit('done',rs) 
            } 
              return false              
          }
      },
      descState() {
        return this.form.desc.length > 0 ? true : false
      },  
           

    },    
    methods :{
      async onSubmit(event) {
            event.preventDefault()
        },
  
        getdata(){
            return this.form
        },

        catImg(imgs) {
            return require(`../assets/${imgs}`);
        },         
        
        onReset(event) {
                event.preventDefault()
                this.$nextTick(() => {

                })
            },          


        onChange(checked) {
            //console.log(`a-switch to ${checked}`);
            let rs={
                pages: 1,
                state:checked,
            }
            this.$emit('done',rs)
        },
  
        
        onHide(evt) {
            if(evt.trigger === "backdrop"){
                evt.preventDefault();
                //this.handleBackdrop();
            }
        }, 
        
        close(){
            this.$emit('close')  
        },

        upsave(){
            this.loading = true 
            let descdata = {
                desc : this.form.desc,
                content : this.editorData
            }            
            this.$emit('updage',3,descdata)
        }, 
        updone(){
            this.loading = false
        },        
        inidata(){
            if(JSON.stringify(this.obj)!=='{}'){ 
                this.editor.txt.html(this.obj.content)
                this.form.desc = this.obj.desc                         
            }
        },
        
        async onupdate(){          
          const self = this;
          setTimeout(() => {
                if(JSON.stringify(self.cobj)!=='{}'){   
                    self.editor.txt.html(self.cobj.content)
                    self.form.desc = self.cobj.desc                      
                    let rs={
                            pages: 5,
                            state:true,
                            data: {
                                desc : self.form.desc,
                                content : self.cobj.content,
                            },
                        }
                    if(self.cobj.content!==null && self.cobj.content!==undefined && self.cobj.desc!==null && self.cobj.desc!==undefined){
                          self.$emit("done", rs);  
                    }                                                  
                } 
            }, "1000");
        },        

    }, 
	mounted () {
        //this.locatorButtonPressed()
        //this.geolocation();

    var editor = new E(this.$refs.editortools);
        editor.config.uploadFileName = 'imgfile'
        editor.config.uploadImgServer = this.uploadurl
        editor.config.uploadImgMaxSize = 3 * 1024 * 1024
        editor.config.uploadImgMaxLength = 1
        editor.config.uploadImgHeaders = {
          "Authorization": "Bearer "+this.status.token,
          "Accept": 'text/x-json',     
        }
        editor.config.zIndex = 5     
        /*editor.config.lang = 'tw'
        editor.config.languages['tw'] = {
            wangEditor: {
                请输入正文: '請輸入正文',
            }
        }
        editor.i18next = window.i18next*/
        editor.config.onchange = (newHtml) => {
          this.editorData = newHtml
          //console.log("newHtml : ",newHtml)
        }
        editor.create();
        this.editor =  editor

        this.inidata()
    },
    beforeDestroy() {
        this.editor.destroy()
        this.editor = null
    }     

}
</script>
<style scoped>
.card-body{
    padding:5px 0px 5px 0px;
}
.ddiv{
    padding:7px;
    border-radius: 17px;
    margin: 5px auto;
    box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
	transition: all .3s ease;
}
.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}



.bton {
   max-height: 100%;  
   max-width: 100%; 
   width: auto;
   height: 40px;    
   position:absolute;
   box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
   bottom: 1750px;  
   left: 26%;  
   right: 0;     
}

div#home a:link { color: #578cc9; }
div#home a:visited { color: #578cc9; }
div#home a:hover { color: #578cc9; }
div#home a:active { color: #578cc9; }
.sava {
  position: fixed;
  right: 0;
  top: 35%;
  width: 8em;
  margin-top: -2.5em;   
}
</style>

