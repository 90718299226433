<template>
    <div id="canImg">      
      <canvas ref="layer1" width="1080" height="540"></canvas>
      <canvas ref="layer2" width="1080" height="540" style="display: none;"></canvas>
      <a-button-group>
        <input type="file" @change="uploadImage" ref="fileInput" style="display: none;" />
        <!-- 下拉選單 -->
        <a-select v-model="imageDisplayMode" @change="onSelectChange" style="margin-left: 8px;">
          <a-select-option value="fill">居中</a-select-option>
          <a-select-option value="center">填滿</a-select-option>
        </a-select>   
        <a-button @click="triggerFileInput">選取圖片</a-button>             
        <a-button @click="mergeLayers" type="primary">下載圖片</a-button>
      </a-button-group>      
    </div>
</template>
  
  <script>
  export default {
    name: 'Canvas_main', 
    data() {
      return {
        imageDisplayMode: 'center', // 預設顯示模式
        currentImageSrc: null,
      };
    },       
    methods: {
      clear(){
        const layer1 = this.$refs.layer1.getContext('2d');
        const layer2 = this.$refs.layer2.getContext('2d');
        const width = this.$refs.layer1.width;
        const height = this.$refs.layer1.height;
        // 清除兩個 Canvas 層的內容
        layer1.clearRect(0, 0, width, height);
        layer2.clearRect(0, 0, width, height);
      },      
      triggerFileInput() {
        // 觸發隱藏的文件輸入元素
        this.$refs.fileInput.click();
        },        
        uploadImage(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = e => {
            this.currentImageSrc = e.target.result;
            this.displayImageOnCanvas1(this.currentImageSrc);
          };
          reader.readAsDataURL(file);
        }
      },  
      
      onSelectChange() {
        // 當選擇變化時，重新繪製圖片
        if(this.currentImageSrc){
          if(this.imageDisplayMode==='center'){
          this.displayImageOnCanvas1(this.currentImageSrc);
          }else{
            this.displayImageOnCanvas2(this.currentImageSrc);
          }	
        }
      },     

      displayImageOnCanvas1(imageSrc) {
        const layer1 = this.$refs.layer1.getContext('2d');
        const layer2 = this.$refs.layer2.getContext('2d');
        const targetWidth = 1080;
        const targetHeight = 540;

        const img = new Image();
        img.onload = () => {
          // 先在 layer2 繪製純白色底色
          layer2.canvas.width = targetWidth;
          layer2.canvas.height = targetHeight;
          layer2.fillStyle = '#FFFFFF'; // 純白色
          layer2.fillRect(0, 0, targetWidth, targetHeight);

          // 再在 layer1 繪製圖片
          layer1.canvas.width = targetWidth;
          layer1.canvas.height = targetHeight;
          layer1.clearRect(0, 0, targetWidth, targetHeight);
          // 直接將圖片縮放到整個 Canvas 的尺寸
          layer1.drawImage(img, 0, 0, targetWidth, targetHeight);
        };
        img.src = imageSrc;
      },


      displayImageOnCanvas2(imageSrc) {
            const layer1 = this.$refs.layer1.getContext('2d');
            const layer2 = this.$refs.layer2.getContext('2d');
            const targetWidth = 1080;
            const targetHeight = 540;

            const img = new Image();
            img.onload = () => {
                // 計算縮放比例以保持圖片比例
                const scale = Math.min(targetWidth / img.width, targetHeight / img.height);

                // 計算新尺寸
                const scaledWidth = img.width * scale;
                const scaledHeight = img.height * scale;

                // 計算圖片居中位置
                const x = (targetWidth - scaledWidth) / 2;
                const y = (targetHeight - scaledHeight) / 2;

                // 先在 layer2 繪製純白色底色
                layer2.canvas.width = targetWidth;
                layer2.canvas.height = targetHeight;
                layer2.fillStyle = '#FFFFFF'; // 純白色
                layer2.fillRect(0, 0, targetWidth, targetHeight);

                // 再在 layer1 繪製圖片
                layer1.canvas.width = targetWidth;
                layer1.canvas.height = targetHeight;
                layer1.clearRect(0, 0, targetWidth, targetHeight); // 清除之前的繪製
                layer1.drawImage(img, x, y, scaledWidth, scaledHeight); // 居中並縮放繪製圖片
            };
            img.src = imageSrc;
        },


      /*mergeLayers() {
        const layer1 = this.$refs.layer1;
        const layer2 = this.$refs.layer2.getContext('2d');

        // 先清除 layer2 的內容
        layer2.clearRect(0, 0, layer2.canvas.width, layer2.canvas.height);

        // 然後將 layer1（圖片）繪製到 layer2（底色）之上
        layer2.drawImage(layer1, 0, 0);

        // 下載合併後的圖像
        const link = document.createElement('a');
        link.download = 'merged-image.png';
        link.href = layer2.canvas.toDataURL('image/png');
        link.click();
        }*/

        mergeLayers() {
          const layer1 = this.$refs.layer1;
          const layer2Canvas = this.$refs.layer2;
          const layer2 = layer2Canvas.getContext('2d');

          // 獲取 layer2 Canvas 的寬高
          const width = layer2Canvas.width;
          const height = layer2Canvas.height;

          // 先清除 layer2 的內容
          layer2.clearRect(0, 0, width, height);

          // 在 layer2 上設置白色背景
          //layer2.fillStyle = 'white';
          // 創建線性漸層作為背景色
          const gradient = layer2.createLinearGradient(0, 0, width, 0); // 水平漸層
          gradient.addColorStop(0, '#d2e9f1'); // 起始顏色
          gradient.addColorStop(0.5, 'white'); // 中間顏色
          gradient.addColorStop(1, '#d7f6ee'); // 結束顏色

          // 將漸層設置為背景色並填充矩形
          layer2.fillStyle = gradient;
          layer2.fillRect(0, 0, width, height);

          // 設置陰影效果
          //layer2.shadowColor = 'rgba(97, 100, 101, 0.4)'; // 半透明黑色陰影
          //layer2.shadowBlur = 15; // 模糊程度
          //layer2.shadowOffsetX = 5; // 水平偏移量
          //layer2.shadowOffsetY = 5; // 垂直偏移量
          // 將 layer1（圖片）繪製到 layer2（漸層背景）之上

          // 然後將 layer1（圖片）繪製到 layer2（底色）之上

          // 繪製黃色長方形底色
          layer2.fillStyle = '#f6f6c2';  
          layer2.fillRect(0, height - 17, width, 25); // 黃色長方形高度為25像素

          // 繪製綠色長方形底色
          layer2.fillStyle = '#c8f6c2';
          layer2.fillRect(0, height - 10, width, 25); // 綠色長方形高度為25像素

          layer2.drawImage(layer1, 0, 0);

          // 調整圖像質量以減少檔案大小
          let quality = 1.0; // 初始質量為最高
          let dataUrl = layer2Canvas.toDataURL('image/jpeg', quality);

          // 檢查檔案大小，並適當調整質量
          while (dataUrl.length > 2 * 1024 * 1024 && quality > 0.1) {
            // 大於 2MB 且質量高於最低閾值
            quality -= 0.1;
            dataUrl = layer2Canvas.toDataURL('image/jpeg', quality);
          }

          // 下載合併後的圖像
          const link = document.createElement('a');
          link.download = 'merged-image.jpg'; // 更改為 jpg
          link.href = dataUrl;
          link.click();
        },

        drawRoundedRect(ctx, x, y, width, height, radius) {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
      }

    }
  };
  </script>
  
