<template>
  <div>
    <br />
    <b-card
      :header="typetitle"
      border-variant="light"
      header-border-variant="light"
      header-bg-variant="light"
      align="center"
      class="card-3"
    >
      <b-card-body style="width: 980px" class="overflow-auto" id="style-3">
        <b-card-text>
          <div class="text-center">
            <a-row type="flex">
              <a-col
                :span="5"
                :offset="1"
                v-for="data in mydata"
                :key="data.vals"
              >
                <div
                  @click="toggleClass(data)"
                  :style="{ cursor: 'pointer' }"
                  :class="['ddiv', data.expanded ? 'ddib' : '']"
                >
                  <span>{{ data.title }}</span>
                </div>
              </a-col>
            </a-row>
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>
    <br />
    <div class="sava" v-if="JSON.stringify(obj) !== '{}'">
      <a-button
        type="primary"
        shape="round"
        icon="save"
        @click="upsave"
        size="large"
        :disabled="category === null"
        :loading="loading2"
        block
      >
        存檔變更
      </a-button>
    </div>

    <b-modal
      ref="my-loading"
      id="modal-1"
      title="活動類別子項目"
      @hide="onHide"
      centered
      hide-footer
    >
      <div class="text-center"></div>
      <div class="text-left">
        <a-table
          :columns="column"
          :row-key="(record) => record.id"
          :data-source="itemlist"
          :pagination="pagination"
          :loading="loading"
          :row-selection="{
            type: 'radio',
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          bordered
        >
        </a-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { typeService } from "../_services";
//import MY_JSON from '../assets/json/typedata.json'
const queryData = () => {
  return typeService.findtyplist();
};

const column = [
  {
    title: "子類別名稱",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "子類別描述",
    dataIndex: "descs",
    scopedSlots: { customRender: "descs" },
  },
];

export default {
  name: "Actypes",
  components: {},
  props: {
    page: {
      type: Number,
    },
    obj: {
      type: Object,
    },
    cobj: {
      type: Object,
    },
  },
  data() {
    return {
      disableds: false,
      typetitle: "選擇活動類型",
      selected: "",
      selecitem: "",
      loading: false,
      //myJson: MY_JSON,
      pagination: { defaultPageSize: 4 },
      iconLoading: false,
      selectedRowKeys: [],
      itemlist: [],
      itemarry: [],
      mydata: [],
      column,
      loading2: false,
      category: null,
    };
  },
  watch: {
    cobj() {
      if (this.cobj !== undefined) {
        if (this.cobj.id > 0) {
          this.onupdate();
        }
      }
    },
  },
  methods: {
    toggleClass(obj) {
      this.itemlist = [];
      this.itemarry = [];
      this.mydata = this.mydata.map((x) => {
        x.expanded = false;
        if (x.vals === obj.vals) {
          x.expanded = !x.expanded;
        }
        return x;
      });
      if (obj.items_type.length > 0) {
        this.itemarry = obj;
        this.itemlist = obj.items_type;
        this.$refs["my-loading"].show();
      }
    },

    catImg(imgs) {
      return require(`../assets/img/${imgs}`);
    },
    cdone(rs) {
      this.$emit("done", rs);
    },

    getdata() {
      let rs = this.$refs.chon1.getdata();
      return rs;
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    close() {
      this.selected = "";
    },
    inidata() {
      queryData().then((obj) => {
        if (obj.code == 200) {
          this.mydata = obj.data;
          this.mydata = this.mydata.map((x) => {
            x.expanded = false;
            if (JSON.stringify(this.obj) !== "{}") {
              //console.log(x)
              if (x.vals === this.obj.vals) {
                x.expanded = true;
                x.items_type.map((y) => {
                  if (y.id === this.obj.aid) {
                    this.selectedRowKeys.push(y.id);
                    this.typetitle =
                      "選擇活動類型" +
                      " 您選擇項目為: " +
                      this.obj.title +
                      " | " +
                      this.obj.atitle;
                  }
                });
              }
            }

            return x;
          });
        }
      });
    },
    onChange(e) {
      console.log(`checked = ${e.target.vals}`);
      let rs = {
        pages: 1,
        state: true,
      };
      this.$emit("done", rs);
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      let obj = this.itemlist.find((item) => item.id == this.selectedRowKeys);
      this.typetitle =
        "選擇活動類型" +
        " 您選擇項目為 : " +
        this.itemarry.title +
        " | " +
        obj.title;
      this.category = {
        id: this.itemarry.id,
        title: this.itemarry.title,
        vals: this.itemarry.vals,
        items_type: obj,
      };
      let rs = {
        pages: 3,
        state: true,
        data: this.category,
      };

      if (JSON.stringify(this.obj) === "{}") {
        this.$emit("done", rs);
      }
      this.$refs["my-loading"].hide();
    },
    upsave() {
      this.loading2 = true;
      this.$emit("updage", 1, this.category);
    },
    updone() {
      this.loading2 = false;
    },
    async onupdate() {
      const self = this;
      setTimeout(() => {
        if (JSON.stringify(self.cobj) !== "{}") {
          self.mydata = self.mydata.map((x) => {
            x.expanded = false;
            if (JSON.stringify(self.cobj) !== "{}") {
              if (x.vals === self.cobj.vals) {
                x.expanded = true;
                x.items_type.map((y) => {
                  if (y.id === self.cobj.aid) {
                    self.selectedRowKeys.push(y.id);
                    self.typetitle =
                      "選擇活動類型" +
                      " 您選擇項目為: " +
                      self.cobj.title +
                      " | " +
                      self.cobj.atitle;
                  }
                });
              }
            }

            return x;
          });

          self.category = {
                id: self.cobj.tid,
                title: self.cobj.title,
                vals: self.cobj.vals,
                items_type: {
                    id: self.cobj.aid,
                    title: self.cobj.atitle,
                    descs: self.cobj.descs,
                    broker: self.cobj.broker,
                    coach: self.cobj.coach,
                    volunteer: self.cobj.volunteer,
                    actor: self.cobj.actor, 
                },
            };          
          let rs = {
            pages: 3,
            state: true,
            data: self.category,
          };

          if(self.cobj.tid!==null && self.cobj.tid!==undefined){
            self.$emit("done", rs);  
          }          

        }
      }, "1000");
    },
  },
  mounted() {
    //this.mydata = this.myJson
    this.inidata();
  },
};
</script>
<style scoped>
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

#style-3 {
  text-align: left;
}

.ddiv {
  padding: 7px;
  border-radius: 17px;
  margin: 10px auto;
  box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
  transition: all 0.3s ease;
}
.ddib {
  padding: 7px;
  border-radius: 17px;
  margin: 10px auto;
  box-shadow: 1px 1px 15px -5px rgb(240, 8, 8);
  transition: all 0.3s ease;
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.sava {
  position: fixed;
  right: 0;
  top: 35%;
  width: 8em;
  margin-top: -2.5em;
}
</style>
