<template>
  <div>
    <br />
    <b-card
      header="開始建立活動資訊！"
      header-border-variant="light"
      header-bg-variant="light"
      align="center"
      class="card-3"
    >
      <b-card-body style="width:980px;padding:5px 0px 5px 0px;" class="overflow-auto">
        <b-card-text>          
          <a-alert v-if="errors!==''" :message="errors" :type="mtyps" show-icon />
          <div v-if="JSON.stringify(obj)==='{}'">
          <a-alert v-if="formdata" message="資料填妥, 確認無誤進行下一步" type="success" />
          </div>
          <img class="bimg" :src="xurl!==''? xurl:catImg('event_1080x540.png')" />
          <div class="text-center">
            <a-upload
              name="imgfile"
              method="post"
              :show-upload-list="false"
              :headers="headers"
              :action="uploadurl"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <a-button
                size="large"
                type="primary"
                shape="round"
                block
                :loading="iconLoading"
              >就缺一張好看的活動照請上傳 1080 X 540 pixel 且小於2MB</a-button>
            </a-upload>
            <a-button type="danger" @click="openPic()" style="margin-left:0.2rem;" shape="circle" icon="picture" :size="'large'" />
          </div>
          <div class="text-left" style="padding:5px 50px 5px 50px;">
            <b-form @submit="onSubmit" @reset="onReset">
              <p class="h6">
                <b-icon-link></b-icon-link>主辦單位
              </p>
              <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;" />
              <b-form-group
                id="input-group-11"
                style="width:350px;"
                label="主辦單位名稱*"
                label-for="input-11"
              >
                <h5>
                  🚩
                  <span v-if="loadata!==undefined" v-text="loadata.name" />
                </h5>
              </b-form-group>

              <p class="h6">
                <b-icon-vinyl-fill></b-icon-vinyl-fill>關於活動
              </p>
              <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;" />
              <b-form-group
                id="input-group-1"
                style="width:80%"
                label="活動名稱*"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.actitle"
                  type="text"
                  placeholder="輸入活動名稱 "
                  :state="actitleState"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
              <p class="h6">
                  <b-icon icon="bookmark-star-fill"></b-icon>課程/活動時間
              </p> 
              <div style="padding-bottom:0.5rem;">               
              <a-switch checked-children="彈性時間" un-checked-children="固定時間" @change="dateChange" :checked="form.datetype.checked" default-unchecked />
              </div>
              <div v-show="form.datetype.checked" style="padding: 0.5rem;">
                <m-date-picker v-model="datelist" :multi="multi" :always-display="true" :format="formatDate"></m-date-picker> 
                <div v-for="item  in form.multiple" :key="item.id" class="ddic">                          
                  <div style="display : inline-block;">活動日期 : {{ item.date }} 
                    <span v-if="item.stime!=='' && item.etime!==''" v-text="'開始時間 :'+item.stime.split(':').slice(0,2).join(':') +' 結束時間 :'+item.etime.split(':').slice(0,2).join(':')"></span></div>
                  開始時間 : <div style="display : inline-block;">                   
                    <a-time-picker :valueFormat="'HH:mm'" :allowClear="false" :inputReadOnly="true" v-model="item.stime" format="HH:mm" />
                  </div>
                  結束時間 : <div style="display : inline-block;">                    
                    <a-time-picker :valueFormat="'HH:mm'" :allowClear="false" :inputReadOnly="true" v-model="item.etime" format="HH:mm" />
                  </div>
                  <div style="display : inline-block;">
                    <span v-if="item.check" style="color:#dd0606;"> ✘請確認活動時間</span> 
                    <span v-else style="color:#0aae06;">✔資料正確</span>
                  </div>                                                 
                </div>
              </div>       
              <div v-show="!form.datetype.checked">
              <p v-if="checkdatetime" class="text-danger"> 
                <span v-text="errormessage" style="font-weight: bold;" />
              </p>                  
              <b-form-group
                id="input-group-2"
                style="width:80%"
                label="活動開始時間*  "
                label-for="input-2"
              >
                <b-form-datepicker
                  id="input-2"
                  :state="sdateState"
                  v-model="form.sdate"
                  style="width:350px"
                  :min="min"                  
                  :label-no-date-selected="'請選擇預約日期'"
                  :label-help="''"
                  @context="onContext"
                  @input="get_data()"
                  class="mb-2"
                ></b-form-datepicker>

                <b-input-group class="mb-3" style="width:240px">
                  <b-form-input
                    id="example-input"
                    v-model="form.stime"
                    :state="!checkdatetime"
                    style="width:100px;"
                    readonly
                    type="text"
                    placeholder=""
                  ></b-form-input> 
                  <b-input-group-append>
                    <div class="form-floating">
                      <b-form-select style="width:70px;height: 38px;" class="form-select" id="floatingSelect1" v-model="selectedHour" :options="hours"></b-form-select>
                      <label for="floatingSelect1">小時</label>
                    </div>                    
                    <div class="form-floating">
                      <b-form-select style="width:70px;height: 38px;" class="form-select" id="floatingSelect2" v-model="selectedMinute" :options="minutes"></b-form-select>
                      <label for="floatingSelect2">分鐘</label>
                    </div>                               
                  </b-input-group-append>               
                </b-input-group>                
              </b-form-group>

              <b-form-group
                id="input-group-22"
                style="width:80%"
                label="活動結束時間*  "
                label-for="input-22"
              >

                <b-form-datepicker
                  id="input-22"
                  :state="edateState"
                  v-model="form.edate"
                  :min="min2" 
                  :max="max2"                    
                  :label-no-date-selected="'請選擇預約日期'"
                  :label-help="''"
                  style="width:350px"
                  class="mb-2"
                ></b-form-datepicker>

                <b-input-group class="mb-3" style="width:240px">
                  <b-form-input
                    id="example-input"
                    v-model="form.etime"
                    :state="!checkdatetime"
                    style="width:100px;"
                    readonly
                    type="text"
                    placeholder=""
                  ></b-form-input> 
                  <b-input-group-append>
                    <div class="form-floating">
                      <b-form-select style="width:70px;height: 38px;" class="form-select" id="floatingSelect1" v-model="selectedHour2" :options="hours"></b-form-select>
                      <label for="floatingSelect1">小時</label>
                    </div>                    
                    <div class="form-floating">
                      <b-form-select style="width:70px;height: 38px;" class="form-select" id="floatingSelect2" v-model="selectedMinute2" :options="minutes"></b-form-select>
                      <label for="floatingSelect2">分鐘</label>
                    </div>                               
                  </b-input-group-append>            
                </b-input-group>
                
              </b-form-group>
              <label v-for="day in form.weeked" :key="day.title" class="label-checkbox" :class="{ 'checked': day.checked }" :for="day.title">
                <input type="checkbox" v-model="day.checked" class="check-day" :id="day.title" :value="day.value" />
                {{ day.title }}
              </label>
              
              </div>
            
              <div style="margin-top:1.2rem;margin-bottom:1.2rem;">
                <p class="h6">
                  <b-icon icon="person-fill"></b-icon>參加人數限制
                </p>                
                <a-switch checked-children="有限制" un-checked-children="無限制" @change="onChange" :checked="form.limits.checked" default-unchecked />               
                <b-form-input
                  v-if="form.limits.checked"
                  id="input-9"
                  v-model="form.limits.value"
                  type="number"
                  :min="0" 
                  :max="30000"
                  style="width:30%;margin-top:0.5rem;"
                  placeholder="輸入活動聯絡,電話號碼 "
                  autocomplete="off"
                  :state="checkState"
                  required
                ></b-form-input>                                             
              </div>

              <p class="h6">
                <b-icon-grid-fill></b-icon-grid-fill>活動聯絡資料
              </p>
              <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;" />
              <b-form-group
                id="input-group-3"
                style="width:80%"
                label="主辦單位 Email*"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.email"
                  type="email"
                  placeholder="輸入主辦單位 Email "
                  autocomplete="off"
                  :state="emailState"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                style="width:250px"
                label="活動電話號碼*  "
                label-for="input-4"
              >
                <b-form-input
                  id="input-4"
                  v-model="form.hphone"
                  type="number"
                  placeholder="輸入活動聯絡,電話號碼 "
                  autocomplete="off"
                  :state="hphoneState"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-6"
                style="width:90%"
                label="活動地點*  "
                label-for="input-6"
              >
                <Addresscom @toaddress="toaddress"  />
                <p></p>
                <p class="text-secondary">詳細地址(道路、街名、巷弄號、樓層)</p>
                <b-form-input
                  id="input-6"
                  v-model="form.address"
                  @change="changeHandler"
                  type="text"
                  placeholder="道路、街名、巷弄號、樓層"
                  autocomplete="off"
                  style="width:80%"
                  :state="addressState"
                  required
                ></b-form-input>
              </b-form-group>
              <GmapMap
                :center="{lat:center.lat, lng:center.lng}"
                :zoom="17"
                map-type-id="terrain"
                style="width: 100%; height: 300px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center=m.position"
                />
              </GmapMap>

              <p class="h6">
                <b-icon-broadcast-pin></b-icon-broadcast-pin>參考網站
              </p>
              <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;" />
              <b-form-group id="input-group-7" style="width:80%" label="官方網站  " label-for="input-7">
                <b-form-input
                  id="input-7"
                  v-model="form.sinkurl"
                  type="url"
                  placeholder="輸入官方網站"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-8"
                style="width:80%"
                label="Facebook  "
                label-for="input-8"
              >
                <b-form-input
                  id="input-8"
                  v-model="form.finkurl"
                  type="url"
                  placeholder="輸入Facebook"
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
            </b-form>
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>

    <br />
            <div class="sava" v-if="JSON.stringify(obj)!=='{}'" >  
                 <a-button type="primary" shape="round" icon="save" @click="upsave" size="large" :disabled="!formdata" :loading="loading" block>
                     存檔變更                              
                 </a-button>
            </div>      
    <b-modal
      ref="my-loading"
      id="modal-2"
      title="Loading"
      @hide="onHide"
      centered
      hide-footer
      hide-header
    >
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <strong>Loading....</strong>
      </div>
    </b-modal>
    <template>
      <a-modal
        :title="'圖片產生器'"
        :visible="visible2"
        :closable="true"
        :maskClosable="false"
        :okText="'刪除'"
        :okType="'danger'"
        :cancelText="'取消'"
        :width="1120"
        :bodyStyle="{ padding: '10px', maxHeight: '600px', overflow: 'auto' }"
        @cancel="handelCancel2"
      >
        <div class="text-center" style="max-width: 1080px; margin: auto;">
          <Canvas ref="conCanvas" />
        </div>
        <template slot="footer">
          <div class="text-center">

          </div>          
        </template>
      </a-modal>
    </template>

  </div>
</template>

<script>
import { mapState } from "vuex";
import Addresscom from "../components/Addresslist.vue"
import Canvas from "../components/Canvas.vue"
import * as moment from "moment/moment";
//import {navigator} from 'vue2-google-maps'

import { gmapApi } from "vue2-google-maps";

export default {
  name: "Mactivity",
  components: {
       Addresscom,
       Canvas,
  },    
  props: {
    page: {
      type: Number
    },
    loadata: {
      type: Object
    },
    obj:{ 
      type:Object
    },
    org:{
      type:Object
    },
    cobj:{ 
      type:Object
    },          
  },
  data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth())
      minDate.setDate(moment(minDate).format("DD"))
      // 15th in two months
      const maxDate = new Date(today)
      maxDate.setMonth(maxDate.getMonth() + 1)
      var lastDayOfMonth = new Date(maxDate.getFullYear(), maxDate.getMonth()+1, 0)
      maxDate.setDate(moment(lastDayOfMonth).format("DD"))

    return {
      multi: true,
      visible2:false,
      datelist: [],  
      multiple: [],   
      loading:false,
      disableds: false,
      insert: true,
      fcheck:1,
      xurl: "",
      errors: "",
      mtyps: "error",
      uploadurl: process.env.VUE_APP_URL +'user/image',
      min: minDate,
      max: maxDate, 
      min2: minDate,
      max2: maxDate,             
      imgs: {
        width: 0,
        height: 0
      },
      center: {
        lat: 25.03746,
        lng: 121.564558
      },
      geocoder: null,
      iconLoading: false,
      errormessage: "結束時間不可小於開始時間",  
      selectedHour: null, // 选中的小时
      selectedMinute: null, // 选中的分钟
      selectedHour2: null, // 选中的小时
      selectedMinute2: null, // 选中的分钟        
      hours: Array.from({ length: 24 }, (v, k) => ({ value: k, text: k < 10 ? '0' + k : k })), // 0-23小时
      minutes: Array.from({ length: 60 }, (v, k) => ({ value: k, text: k < 10 ? '0' + k : k })), // 0-59分钟             
      form: {
        url: "",
        actitle: "",
        sdate: moment().format('YYYY-MM-DD'),
        stime: moment().format('HH:mm'),
        edate: moment().format('YYYY-MM-DD'),
        etime: moment().add({hours:1}).format('HH:mm'),
        //etime: moment().add({hours:1, minutes: 30}).format('HH:mm'),
        email: "",
        hphone: "",
        mphone: "",
        address: "",
        sinkurl: "",
        finkurl: "",
        center: {
          lat: "",
          lng: ""
        },
        weeked:[
          { title: '星期一', value: 1 ,checked : false},
          { title: '星期二', value: 2 ,checked : false},
          { title: '星期三', value: 3 ,checked : false},
          { title: '星期四', value: 4 ,checked : false},
          { title: '星期五' , value: 5 ,checked : false},
          { title: '星期六', value: 6 ,checked : false},
          { title: '星期日', value: 0 ,checked : false},
        ],
        limits:{
          checked:false,
          value:0
        }, 
        datetype:{
          checked:false,
        },
        multiple:[]       
      },
      markers: [
        {
          position: { lat: 20, lng: 20 }
        }
      ]
    };
  },

  watch: {
    datelist(val) {
      let tmp =[]
      if(this.insert){
        for (let i = 0; i < val.length; i++) {
        const item = moment(val[i]).format('YYYY-MM-DD');
        const result = this.form.multiple.filter(word => word.date == item);
        if(result.length>0){
          tmp.push(result[0])
        }else{
          //tmp.push({date : item,stime:this.form.stime.split(':').slice(0,2).join(':') ,etime:this.form.etime.split(':').slice(0,2).join(':') ,id:val[i].getTime()+Math.random(),check:true})
          tmp.push({date : item,stime:this.form.stime.split(':').slice(0,2).join(':') ,etime:this.form.etime.split(':').slice(0,2).join(':') ,id:this.uuid(),odate:val[i]})
        }
      } 
        if(tmp.length>0){
          this.form.multiple = tmp.sort((a,b) => moment(a.date).toDate() - moment(b.date).toDate());
          this.form.sdate = this.form.multiple[0].date
          this.form.edate = this.form.multiple[this.form.multiple.length-1].date        
        }else{
          this.form.multiple = tmp
        }       
      }
    },

    org(val) {
        this.form.email=val.email===undefined?'':val.email
        this.form.hphone=val.hphone===undefined?'':val.hphone
        this.form.finkurl=val.Finkurl===undefined?'':val.Finkurl
        this.form.sinkurl=val.Sinkurl===undefined?'':val.Sinkurl
        this.form.address=val.address===undefined?'':val.address 
        if(this.form.address!=='' && this.form.address!==undefined){
          this.getgeocode(this.form.address);
        }
    },    

    'form.multiple': {
            handler() {
              if(this.form.multiple!==null){
                for (let i = 0; i < this.form.multiple.length; i++) {
                  this.form.multiple[i].stime = this.form.multiple[i].stime.split(':').slice(0,2).join(':')
                  this.form.multiple[i].etime = this.form.multiple[i].etime.split(':').slice(0,2).join(':')
                  if(this.form.multiple[i].etime>this.form.multiple[i].stime){
                    this.form.multiple[i].check = false
                  }else if(this.form.multiple[i].etime===this.form.multiple[i].stime){
                    this.form.multiple[i].check = true                  
                  }else if(this.form.multiple[i].etime<this.form.multiple[i].stime){
                    this.form.multiple[i].check = true
                  }
                }  
                if( this.form.multiple.length>0){
                  this.form.stime = this.form.multiple[0].stime
                  this.form.etime = this.form.multiple[this.form.multiple.length-1].etime
                }

              }
               
            },
            deep: true
        },

        cobj() {
            if (this.cobj!==undefined) {
                if(this.cobj.id>0){
                    this.onupdate()
                }
            }
        },

        // 监听小时和分钟的变化，当它们改变时更新form.stime
        selectedHour() {
          this.updateTime();
        },
        selectedMinute() {
          this.updateTime();
        },   
        selectedHour2() {
          this.updateTime2();
        },
        selectedMinute2() {
          this.updateTime2();
        }               
        
  },  
  computed: {
    ...mapState("account", ["status", "user"]),
    google: gmapApi,

    headers() {
      return {
        "X-Requested-With": null,
        Authorization: "Bearer " + this.status.token
      };
    },

    formdata() {
      this.setWeek2(this.form.sdate);
      if(this.form.datetype.checked==true){
          if(this.form.multiple.length===0){
            let rs = {
              pages: 4,
              state: false,
              data: null
            };
            if(JSON.stringify(this.obj)==='{}'){ 
              this.$emit("done", rs);
            }            
            return false
          }
      }

      if (
        this.form.actitle !== "" &&
        this.form.sdate !== "" &&
        this.form.stime !== null &&
        this.form.edate !== "" &&
        this.form.etime !== null &&
        this.form.email !== "" &&
        this.form.hphone !== "" &&
        this.form.address !== ""
      ) {        
        let rs = {
          pages: 4,
          state: true,
          data: this.form
        };
        if(this.form.limits.checked==true){
          if(this.form.limits.value==0){
            return false
          }
          if(JSON.stringify(this.obj)==='{}'){ 
            this.$emit("done", rs);          
          }
        }else{
          if(JSON.stringify(this.obj)==='{}'){ 
            this.$emit("done", rs);
          }
        }       
        return true;
      } else {
        let rs = {
          pages: 4,
          state: false,
          data: null
        };
        if(JSON.stringify(this.obj)==='{}'){ 
          this.$emit("done", rs);
        }
        return false;
      }
    },
    actitleState() {
      return this.form.actitle.length > 0 ? true : false;
    },

    sdateState() {
      return this.form.sdate.length > 0 ? true : false;
    },

    edateState() {
      return this.form.edate.length > 0 ? true : false;
    },

    checkdatetime() {
      if (
        this.form.sdate !== "" &&
        this.form.stime !== null &&
        this.form.edate !== "" &&
        this.form.etime !== null
      ) {
        if (this.form.sdate > this.form.edate) {
          return true;
        }
        if (
          this.form.sdate === this.form.edate &&
          this.form.stime > this.form.etime
        ) {
          return true;
        }
        if (
          this.form.sdate === this.form.edate &&
          this.form.stime === this.form.etime
        ) {
          return true;
        }
      }
      return false;
    },

    checkdatetime2() {
      if (
        this.form.stime !== null &&
        this.form.etime !== null
      ) {
        if (
          this.form.sdate === this.form.edate &&
          this.form.stime === this.form.etime
        ) {
          return true;
        }
      }
      return false;
    },    

    emailState() {
      return this.form.email.length > 0 ? true : false;
    },

    hphoneState() {
      return this.form.hphone.length > 0 ? true : false;
    },

    checkState() {
      return this.form.limits.value > 0 ? true : false;
    },  

    addressState() {
      return this.form.address.length > 0 ? true : false;
    }
  },
  methods: {

    updateTime() {
      const hour = this.selectedHour !== null ? this.selectedHour.toString().padStart(2, '0') : '00';
      const minute = this.selectedMinute !== null ? this.selectedMinute.toString().padStart(2, '0') : '00';
      this.form.stime = `${hour}:${minute}`;
      //console.log('起:',this.form.stime.split(":").slice(0, 2).join(":"))
    },   
    updateTime2() {
      const hour = this.selectedHour2 !== null ? this.selectedHour2.toString().padStart(2, '0') : '00';
      const minute = this.selectedMinute2 !== null ? this.selectedMinute2.toString().padStart(2, '0') : '00';
      this.form.etime = `${hour}:${minute}`;
      //console.log('訖:',this.form.etime.split(":").slice(0, 2).join(":"))
    },      

    async onSubmit(event) {
      event.preventDefault();
    },

    geolocation() {
      this.google.geolocation.getCurrentPosition(position => {
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
      });
    },

    get_data(){
      this.setWeek(this.form.sdate);
    },

    uuid() {
      var s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },    

    check_data(rs){
      if(rs.stime!=='' && rs.etime!==''){
        this.set_check(rs)
        if(rs.etime.split(':').slice(0,2).join(':')>rs.stime.split(':').slice(0,2).join(':')){
          this.set_check(rs.id,false)
        }else if(rs.etime.split(':').slice(0,2).join(':')<rs.stime.split(':').slice(0,2).join(':')){
          this.set_check(rs.id,true)
        }
      }
    },

    set_check(id,va1){
      for (var i = 0; i < this.form.multiple.length; i++) {
            if(this.form.multiple[i].id===id){
              this.form.multiple[i].check = va1;
              break
            }
          }
    },

    set_time(){
      for (var i = 0; i < this.form.multiple.length; i++) {
              this.form.multiple[i].stime =  this.form.stime.split(':').slice(0,2).join(':');
              this.form.multiple[i].etime =  this.form.etime.split(':').slice(0,2).join(':');
          }
    },

    formatDate(va1) {
        return va1.toLocaleDateString();
      },

    onContext(ctx) {
        var currentDate = null
        //var getdate = currentDate.getDate();
        if(JSON.stringify(this.obj)!=='{}' && this.fcheck<=2){ 
           currentDate = moment(this.obj.edate).toDate();
           this.fcheck++
        }else{
           currentDate = moment(ctx.selectedYMD).toDate();
        }
        if(this.form.edate!=''){
          if(moment(currentDate).format("YYYY-MM-DD")>this.form.edate){
            this.form.edate = moment(currentDate).format("YYYY-MM-DD")
          }          
        }
        const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        // 15th two months prior
        const minDate = new Date(today)
        minDate.setMonth(minDate.getMonth())
        minDate.setDate(moment(minDate).format("DD"))
        // 15th in two months
        const maxDate = new Date(today.getTime() + (86400 * 31000))
        //maxDate.setMonth(maxDate.getMonth() + 1)
        //maxDate.setDate(maxDate.getDay()+30)
        var lastDayOfMonth = new Date(maxDate)
        //console.log(moment(lastDayOfMonth).format('YYYY-MM-DD'))
        maxDate.setDate(moment(lastDayOfMonth).format("DD"))    
        this.min2 = minDate
        this.max2 = maxDate 
      } ,    

    onChange(checked) {
      this.form.limits.checked = checked
      this.form.limits.value=0
    },    
    dateChange(checked) {
      this.form.datetype.checked = checked
    },  

    getgeocode(address) {
      //var geocoder = new VueGoogleMaps.Geocoder();
      var geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status == "OK") {
          this.center.lat = results[0].geometry.location.lat();
          this.center.lng = results[0].geometry.location.lng();
          this.markers[0].position.lat = this.center.lat;
          this.markers[0].position.lng = this.center.lng;
          this.form.center = this.center;
        } else {
          // 若轉換失敗...
          console.log(status);
        }
      });
    },

    locatorButtonPressed() {
      this.google.geolocation.getCurrentPosition(
        position => {
          this.center.lat = position.coords.latitude;
          this.center.lng = position.coords.longitude;
        },
        error => {
          console.log(error.message);
        }
      );
    },

    groupBy(array, groups, valueKey) {
      var map = new Map();
      groups = [].concat(groups);
      return array.reduce((r, o) => {
        groups.reduce((m, k, i, { length }) => {
          var child;
          if (m.has(o[k])) return m.get(o[k]);
          if (i + 1 === length) {
            child = Object.assign(...groups.map(k => ({ [k]: o[k] })), {
              [valueKey]: 0
            });
            r.push(child);
          } else {
            child = new Map();
          }
          m.set(o[k], child);
          return child;
        }, map)[valueKey] += +o[valueKey];
        return r;
      }, []);
    },  

    toaddress(val,city,village,town){
      this.form.address = val
      this.getgeocode(val);
      console.log(city,village,town)
    },  
    
    changeHandler(){
      this.getgeocode(this.form.address);
    },

    getdata() {
      return this.form;
    },

    catImg(imgs) {
      return require(`../assets/${imgs}`);
    },

    onReset(event) {
      event.preventDefault();
      this.$nextTick(() => {});
    },

    async handleChange({ file }) {
      if (file.status === "done") {
        this.xurl = file.response.Location;
        this.form.url = this.xurl;
        //this.$refs['my-loading'].hide()
        this.iconLoading = false;
      }
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        //this.handleBackdrop();
      }
    },

    close() {
      this.$emit("close");
    },

    enterIconLoading() {
      this.iconLoading = { delay: 1500 };
    },

    upsave(){
      this.loading = true 
      this.setWeek2(this.form.sdate);
      this.$emit('updage',2,this.form)
    }, 
    updone(){
        this.loading = false
    },     

    async beforeUpload(file) {
      return new Promise((resolve, reject) => {
        this.errors = "";
        //this.$emit('upmessage',null)
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        this.iconLoading = true;
        img.onload = () => {
          const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png";
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (img.width !== 1080 || img.height !== 540) {
            this.errors = "您上傳的圖片小於 1080 X 540 pixel !";
            this.mtyps = "error";
            this.xurl = "";
            this.iconLoading = false;
            reject(file);
          } else if (!isJpgOrPng) {
            //this.$message.error('您的檔案必須是 JPG or PNG!');
            this.errors = "您的檔案必須是 JPG or PNG!";
            this.mtyps = "error";
            this.xurl = "";
            this.iconLoading = false;
            //alert('您的檔案必須是 JPG or PNG!')
            //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
            reject(file);
          } else if (!isLt2M) {
            //this.$message.error('您的檔案必須是小於 2MB!');
            this.errors = "您的檔案必須是小於 2MB!";
            this.mtyps = "error";
            this.xurl = "";
            this.iconLoading = false;
            //alert('您的檔案必須是小於 3MB!')
            //this.$emit('upmessage','您的檔案必須是小於 2MB!')
            reject(file);
          } else {
            //this.$refs['my-loading'].show()
            resolve(file);
          }
        };
      });
    },
    setWeek(val){      
      const dayOfWeek = new Date(val).getDay(); 
      for(var i in this.form.weeked){
        if(this.form.weeked[i].value==dayOfWeek){
          this.form.weeked[i].checked=true
        }else{
          this.form.weeked[i].checked=false
        }
      }
    },
    setWeek2(val){      
      const dayOfWeek = new Date(val).getDay(); 
      for(var i in this.form.weeked){
        if(this.form.weeked[i].value==dayOfWeek){
          this.form.weeked[i].checked=true
          break
        }
      }
    },

    inidata(){
        if(JSON.stringify(this.obj)!=='{}'){ 
            this.form.url = this.obj.url
            this.form.actitle = this.obj.actitle
            this.form.sdate= this.obj.sdate
            this.form.stime= this.obj.stime
            this.form.edate= this.obj.edate
            this.form.etime= this.obj.etime
            this.form.email= this.obj.email
            this.form.hphone= this.obj.hphone
            this.form.mphone= this.obj.mphone
            this.form.address= this.obj.address
            this.form.sinkurl= this.obj.sinkurl
            this.form.finkurl= this.obj.finkurl
            this.form.center.lat= this.obj.lat
            this.form.center.lng=this.obj.lng  
            this.xurl = this.obj.url  
            this.form.weeked = this.obj.weekeds
            this.form.limits = {
              checked:this.obj.checked,
              value:this.obj.value
            }
            this.form.datetype ={
              checked:this.obj.datetype
            } 
            this.form.multiple = this.obj.multiples
            this.center= {
              lat: this.obj.lat,
              lng: this.obj.lng 
            }  
            if(this.form.datetype.checked){
              this.insert = false
              for (let i = 0; i < this.obj.multiples.length; i++) {
                const unixTimeZero = moment(this.obj.multiples[i].odate).toDate();                
                this.datelist.push(unixTimeZero)
              }

              this.insert = true
            }
            
        }else{
          this.setWeek(this.form.sdate);
        }
    },
    openPic(){
      this.visible2=true      
      setTimeout(() => {
            if(this.$refs["conCanvas"].clear()!==undefined){
                this.$refs["conCanvas"].clear();   
            }   
            }, 100);      
    },
    handelCancel2(){
      this.visible2=false
    },      

    async onupdate(){
                       
        const self = this;
        setTimeout(() => {
                if(JSON.stringify(self.cobj)!=='{}'){   
                      self.form.url = self.cobj.url
                      self.form.actitle = self.cobj.actitle
                      self.form.sdate= self.cobj.sdate
                      self.form.stime= self.cobj.stime
                      self.form.edate= self.cobj.edate
                      self.form.etime= self.cobj.etime
                      self.form.email= self.cobj.email
                      self.form.hphone= self.cobj.hphone
                      self.form.mphone= self.cobj.mphone
                      self.form.address= self.cobj.address
                      self.form.sinkurl= self.cobj.sinkurl
                      self.form.finkurl= self.cobj.finkurl
                      self.form.center.lat= self.cobj.lat
                      self.form.center.lng=self.cobj.lng  
                      this.xurl = self.cobj.url  
                      self.form.weeked = self.cobj.weekeds
                      self.form.limits = {
                        checked:self.cobj.checked,
                        value:self.cobj.value
                      }
                      self.form.datetype ={
                        checked:self.cobj.datetype
                      } 
                      self.form.multiple = self.cobj.multiples
                      this.center= {
                        lat: self.cobj.lat,
                        lng: self.cobj.lng 
                      }  
                      
                      if(self.form.datetype.checked && self.cobj.multiple!== undefined){
                        this.insert = false
                        for (let i = 0; i < self.cobj.multiple.length; i++) {
                          const unixTimeZero = moment(self.cobj.multiple[i].odate).toDate();                
                          self.datelist.push(unixTimeZero)
                        }
                        self.insert = true
                      }  
                      
                      let rs = {
                        pages: 4,
                        state: true,
                        data: self.form
                      };

                      if(self.cobj.actitle!==null && self.cobj.actitle!==undefined){
                          self.$emit("done", rs);  
                      }                                             
                    } 
                }, "1000");
      },   


  },
  mounted() {
    //this.locatorButtonPressed()
    //this.geolocation();
    this.inidata()
  },
  beforeDestroy() {}
};
</script>
<style scoped>
.card-body {
  padding: 5px 0px 5px 0px;
}
.ddiv {
  padding: 7px;
  border-radius: 17px;
  margin: 5px auto;
  box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
  transition: all 0.3s ease;
}
.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.bimg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.bton {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: 40px;
  position: absolute;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  bottom: 1838px;
  left: 26%;
  right: 26%;
}
div#home a:link {
  color: #578cc9;
}
div#home a:visited {
  color: #578cc9;
}
div#home a:hover {
  color: #578cc9;
}
div#home a:active {
  color: #578cc9;
}
.sava {
  position: fixed;
  right: 0;
  top: 35%;
  width: 8em;
  margin-top: -2.5em;   
}

.label-checkbox {
  margin-right: 0.87rem;
  margin-left: auto;
  border: 1px solid #4273DE;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4273DE;
}

.check-day {
  visibility: hidden;
  position: absolute;
  right: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.checked {
  background: #4273DE;
  color: #fff;
}

.checked::before {
  content: "✔";
}

.ddic{
      padding:8px;
      text-align: left;
      border-radius: 2px;
      border-width:1px;
      width:60%;
      margin-left: 1rem;
      margin-top: 0.5rem;
      border-style:dotted;
      border-color:#b4b3b2;
      transition: all .3s ease;
  }
   

</style>
<style>
.selected-date {
  display:none
}
</style>

