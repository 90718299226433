<template>
    <div class="menu">
        <div style="position: fixed;top:0; left:0;width: 100%;z-index:999;background: linear-gradient(-45deg,#02adc8 0%,#17a2b8 40%,#72d5e4 70%,#07798b 100%);">
            <b-navbar toggleable="lg" type="dark" >
            <b-navbar-nav class="mx-auto">
            <b-navbar-brand >
                <div>
                    <b-icon size="lg" icon="soundwave" variant="light"></b-icon>          
                    <span class="fs-5" v-text="title.title" :style="{ 'margin-left': '0.2rem'}"></span> 
                </div>               
            </b-navbar-brand>
            <span style="width:300px"></span>
            <b-collapse id="nav-collapse" v-if="title.title!=='建立帳號'" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-navbar-nav>
                        <router-link class="nav-link" to="/"><b-icon size="lg" icon="arrow-left-square" variant="light"></b-icon> 回首頁 </router-link>
                    </b-navbar-nav> 
                    <b-navbar-nav>
                        <router-link class="nav-link" :to="{name:'Center'}"><b-icon size="lg" icon="laptop" variant="light"></b-icon> 回主辦中心 </router-link>
                    </b-navbar-nav>                               
                </b-navbar-nav>
            </b-collapse>
            </b-navbar-nav>
            </b-navbar>

        </div>
        <div style="padding:25px;"></div>         
    </div>
</template>

<script>

export default {
  name: 'Evenmenubar',
  components:{
  },
  props: {
    msg: {
        type:String
    },
    title:{
        type:Object
    },  
  },
  methods: {
  }
}
</script>
<style scoped>

</style>