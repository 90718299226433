<template>
    <div>   
            <br>
            <b-card
                header="快來介紹你的講師有多厲害！"
                header-border-variant="light"
                header-bg-variant="light"
                align="center"
                class="card-3"            
                >
               
            <b-card-body style="width:980px;padding:5px 0px 5px 0px;" class="overflow-auto">
            <b-card-text> 
                <a-alert v-if="errors!==''" :message="errors" :type="mtyps" show-icon /> 
                <div v-if="JSON.stringify(obj)==='{}'">
                <a-alert v-if="formdata" message="資料填妥, 確認無誤進行下一步" type="success" /> 
                </div>
            <div class="text-left" style="padding:5px 50px 5px 50px;">
                <p class="h6"><b-icon-person-square></b-icon-person-square> 新增講師</p> 

                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;"> 
                <p align="right">
                <a-button class="bton" v-if="menber.length==0" style="margin-right: 0.5rem;" @click="nouser()" size="large"  shape="round">
                <a-icon type="user-delete" :style="{ fontSize: '26px' }" :loading="loading2" ></a-icon>
                    無講師
                </a-button>
                <a-button class="bton"  @click="adduser('add')" size="large" type="danger" shape="round">
                <a-icon type="user-add" :style="{ fontSize: '26px' }" ></a-icon>
                    新增講師
                </a-button>
                </p> 
                <div style="padding:20px;"></div>
                <div class="text-center">
                    <a-row type="flex" >
                        <a-col :span="4" :offset="1" v-for="data in menber" :key="data.uid">
                                <div style="padding:5px;" @click="updatem(data.uid)">                                           
                                    <b-avatar :src="data.url" :style="{ cursor: 'pointer'}"  variant="primary" text="data.name" size="7rem" class="align-baseline"></b-avatar>
                                    <br>
                                    <span  >{{data.name}} ({{data.title}})</span>
                                </div>                            
                        </a-col>                  
                    </a-row>  
                </div> 
                         
            </div>                            
            </b-card-text>
            </b-card-body>
            </b-card>

            <br>
            <div class="sava" v-if="JSON.stringify(obj)!=='{}'" >  
                 <a-button type="primary" shape="round" icon="save" @click="upsave" size="large" :disabled="!formdata" :loading="loading" block>
                     存檔變更                              
                 </a-button>
            </div>               
        <b-modal ref="my-loading" id="modal-2" size="lg" title="新增講師" @hide="onHide" centered hide-footer>
          <div class="overflow-auto" style="height: 600px;width:100%;" id="style-3">
              
            <div class="text-center">                  
                <a-upload
                    name="imgfile"
                    list-type="picture"
                    class="avatar-uploader"
                    method='post'
                    :show-upload-list="false"
                    :headers="headers"
                    :action="uploadurl"
                    :before-upload="beforeUpload"
                    @change="handleChange" 
                >  
                <b-avatar :src="form.url" @click="beforeUpload" variant="primary" v-if="showpanels" text="" size="9rem" class="align-baseline"></b-avatar>  
                </a-upload>

            </div>
            <a-alert v-if="merrors!=''" :message="merrors" type="error" show-icon />
            <p></p>
            <!--<p><a-button type="dashed" shape="round" @click="showpanels =!showpanels"  block> <a-icon type="user"></a-icon>從會員裡選取講師</a-button></p>-->
            <div class="text-center" v-if="!showpanels">
                <div class="text-center">  
                    <p class="h6"><b-icon-vinyl-fill></b-icon-vinyl-fill>人員清單</p>                        
                </div>    
                <div class="text-left">
                  <a-input-search placeholder="請輸入查詢名稱" style="margin: 10px;width: 200px" enter-button @search="onSearch" /> 
                </div>                      
                <div class="overflow-auto" style="height: 410px;width:100%;" id="style-3">
                    <div class="text-left">                      
                        <a-table
                            :columns="mcolumns"
                            :row-key="record => record.id"
                            :data-source="udata"
                            :pagination="upagination"
                            :loading="uloading"
                            :row-selection="{ type:'radio',selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                            @change="uhandleTableChange"
                            bordered
                        >
                            <template slot="pri_url" slot-scope="pri_url"> 
                                <b-avatar :src="pri_url" variant="primary" text="" size="2rem" class="align-baseline"></b-avatar> 
                            </template> 
                            
                            <template slot="username" slot-scope="record"  >
                                <div>
                                    <span v-if="record.username!=''" style="color:#F08C3C;" v-text="maskName(record.username)"></span>                                   
                                </div>
                            </template> 
                            <template slot="display_name" slot-scope="record"  >
                                <div>
                                    <span v-if="record.display_name!=''" style="color:#2c7190;" v-text="record.display_name"></span>                                    
                                </div>
                            </template>                               

                        </a-table>
                    </div>
                </div>
            </div>

            <div class="text-left" v-if="showpanels">  
                <b-form>                       
                    <p class="h6"><b-icon-vinyl-fill></b-icon-vinyl-fill> 關於</p> 
                    <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">
                    <b-form-group id="input-group-1"  label="講師姓名*" label-for="input-1">
                        <b-input-group prepend="" style="width:350px" class="mt-3">
                                <b-form-input
                                    id="input-1"
                                    v-model="form.name"
                                    type="text"
                                    placeholder="輸入講師姓名稱 " 
                                    :state="nameState"  
                                    autocomplete="off"  
                                    :readonly="true"                      
                                    required
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="link" @click="showpanels =!showpanels" pill>
                                        <b-avatar variant="primary" size="sm"></b-avatar>
                                        選取講師
                                    </b-button>
                                </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <p class="h6"></p> 
                    <div style="padding:5px;">
                        <b-icon-person-lines-fill></b-icon-person-lines-fill><span v-text="'授予點名 : '"></span>
                        <a-switch checked-children="是" style="margin-right: 1rem;" un-checked-children="否" @change="orderChange" :checked="form.checked" default-unchecked />
                        <b-icon-award-fill></b-icon-award-fill><span v-text="'是否獲得學分 : '"></span> 
                        <a-switch checked-children="是" un-checked-children="否" @change="scoreChange" :checked="form.getscore" default-unchecked />
                    </div>                         
                    <b-form-group id="input-group-2" style="width:80%" label="職稱*  " label-for="input-2">
                        <b-form-input
                            id="input-2"
                            v-model="form.title"
                            type="text"
                            placeholder="輸入職稱 "
                            rows="8"
                            autocomplete="off"
                            :state="titleState"                        
                            required
                        ></b-form-input>
                    </b-form-group>      
                    
                    <p class="h6"><b-icon-grid-fill></b-icon-grid-fill> 個人介紹</p>   
                    <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">                
                    <b-form-group id="input-group-3" style="width:80%" label="公司名稱*" label-for="input-3">
                        <b-form-input
                            id="input-3"
                            v-model="form.company"
                            type="text"
                            placeholder="輸入公司名稱 "
                            autocomplete="off"
                            :state="companyState" 
                            required
                        ></b-form-input>
                    </b-form-group>  

                    <b-form-group id="input-group-2" style="width:98%" label="嘉賓簡介*  " label-for="input-2">
                        <b-form-textarea
                            id="input-2"
                            v-model="form.desc"  
                            type="text"
                            placeholder="輸入嘉賓簡介 "
                            rows="8"
                            autocomplete="off"
                            :state="descState"                        
                            required
                        ></b-form-textarea>
                    </b-form-group>                                                                     
    
                    <p class="h6"><b-icon-tree-fill></b-icon-tree-fill> 媒體連結</p> 
                    <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;"> 

                    <b-form-group id="input-group-8" style="width:80%" label="Facebook  " label-for="input-8">
                        <b-form-input
                            id="input-8"
                            v-model="form.finkurl"
                            type="url"
                            placeholder="輸入Facebook"
                            autocomplete="off"
                            required
                        ></b-form-input>  
                    </b-form-group>    

                    <b-form-group id="input-group-9" style="width:80%" label="Instagram  " label-for="input-9">
                        <b-form-input
                            id="input-9"
                            v-model="form.iinkurl"
                            type="url"
                            placeholder="輸入Instagram"
                            autocomplete="off"
                            required
                        ></b-form-input>
                    </b-form-group> 
                    <input type="hidden" name="customfield" class="form-control" :value="form.uid">                
                </b-form> 
            </div>
          </div>
            <div>                
                <a-button v-if="decide===false" @click="onSubmit" size="large" type="primary" :disabled="form.uid=='' || form.desc=='' || form.company=='' || form.title=='' ||  form.name=='' || merrors!==''" block :loading="iconLoading">新增講師</a-button>
                <a-button-group v-if="decide">
                <a-button type="primary" @click="modifys('update', form)" size="large" icon="edit" :disabled="form.uid=='' || merrors!==''" :loading="iconLoading" >修改</a-button>
                <a-button type="danger" @click="modifys('delete', form)"  size="large" icon="delete" :loading="iconLoading" >刪除</a-button>
                </a-button-group>               
            </div>          
        </b-modal> 
            
    </div>
</template>

<script>
import {mapState} from 'vuex';
import { cmessService } from '../_services'

const queryuser = params => {
    return cmessService.findusListName(params.page,params.name)
}
const mcolumns = [
  {
    title: '圖像',
    dataIndex: 'pri_url',
    width: '20%',    
    scopedSlots: { customRender: 'pri_url' },    
  },       
  {
    title: '註冊帳號',
    dataIndex: '',
    scopedSlots: { customRender: 'username' },      
    width: '30%',
  },   
  {
    title: 'Line顯示名稱',
    dataIndex: '',
    scopedSlots: { customRender: 'display_name' },      
    width: '30%',
  },  

];

export default {
    name: 'Mcoach',    
    props:{
          page:{
              type:Number
          },
          obj:{ 
              type:Object
          },
          cobj:{ 
            type:Object
          },                     
        },    
    data(){
        return {
            loading:false,
            loading2:false,
            errors:'',
            mtyps:'error',
            uploadurl:process.env.VUE_APP_URL +'user/image',  
            menber:[],   
            name:"all", 
            iconLoading: false,
            decide : false,
            showpanels : true,
            mcolumns,
            udata: [], 
            userid:'',
            merrors:'',
            upagination: {defaultPageSize:4}, 
            uloading: false,    
            ckms:'0',            
            selectedRowKeys: [],                   
            form: {
                 id:'',
                 uid:'',
                 desc : '',            
                 name : '',
                 title : '' ,
                 company : '',
                 finkurl : '',
                 iinkurl : '',
                 url:'',
                 checked:true,
                 getscore:true,
                },                                    
                
        }
    },
    watch: {
        cobj() {
        if (this.cobj !== undefined) {
            if (this.cobj.id > 0) {
            this.onupdate();
            }
        }
        },
    },     
    computed:{
      ...mapState('account',['status','user']),
      headers(){   
        return{                    
          "X-Requested-With":null,
          "Authorization": "Bearer "+this.status.token,
        }
      },
          
      formdata(){    
          if(this.menber.length>0){ 
            let rs={
                pages: 6,
                state:true,
                data:this.uniqueObjArr(this.menber),
            }   
            if(JSON.stringify(this.obj)==='{}'){   
                this.$emit('done',rs)              
            }           
            return true
          }else{
            let rs={
                pages: 6,
                state:false,
                data:null,
            }   
            if(JSON.stringify(this.obj)==='{}'){   
                this.$emit('done',rs)              
            }
              return false
          }
      },
      descState() {
        return this.form.desc!=='' ? true : false
      },

      nameState() {
        return this.form.name!=='' ? true : false
      },          
           
      titleState() {
          return this.form.title!=='' ? true : false
      },

      companyState() {
          return this.form.company!=='' ? true : false
      }
        

    },    
    methods :{
      async onSubmit(event) {
            event.preventDefault()
            this.iconLoading = true                                    
            setTimeout(() => {
                let temp ={
                        desc:this.form.desc,
                        name:this.form.name,
                        displayname:"",
                        uid:this.form.uid,
                        title:this.form.title,
                        company:this.form.company,
                        finkurl:this.form.finkurl,   
                        iinkurl:this.form.iinkurl,      
                        url:this.form.url,
                        checked:this.form.checked,
                        getscore:this.form.getscore,
                    }                                       
                this.menber.push(temp)
                this.iconLoading = false
                this.clearn()
                this.$refs['my-loading'].hide()
            }, 1300);             
        },

        maskName(name) {
            const len = name.length;
            const half = Math.ceil(len / 2);
            const maskedPart = '*'.repeat(half);
            const visiblePart = name.slice(half);
            return maskedPart + visiblePart;
        },        

        uniqueObjArr(arr) { //distinct       
          var unique = Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse);
          return unique;
        },        

        updatem(id){
             this.decide=true
             this.showpanels=true             
             this.userid= id
             this.fillin(this.menber.filter((el)=> { return el.uid === id}))
             this.$refs['my-loading'].show()
        },

        clearn(){
            this.form.id =''
            this.form.uid=''
            this.form.desc=''
            this.form.name =''  
            this.form.title =''
            this.form.company =''
            this.form.finkurl =''     
            this.form.iinkurl =''       
            this.form.url =''
            this.form.checked = true     
            this.form.getscore = true                        
        },

        fillin(rs){
            this.form.id = rs[0].id
            this.form.uid = rs[0].uid
            this.form.desc=rs[0].desc
            this.form.name =rs[0].name 
            this.form.title =rs[0].title 
            this.form.company =rs[0].company 
            this.form.finkurl =rs[0].finkurl     
            this.form.iinkurl =rs[0].iinkurl       
            this.form.url =rs[0].url   
            this.form.checked =rs[0].checked===(undefined || null)? true: rs[0].checked
            this.form.getscore =rs[0].getscore===(undefined || null)? true: rs[0].getscore
        },

        adduser(type){
            this.clearn()
            this.decide = false
            this.showpanels = true            
            this.$refs['my-loading'].show()
            console.log(type)
        },

        nouser(){    
            if(JSON.stringify(this.obj)!=='{}'){  
                this.loading2=true   
                this.$emit('updage',4,null)
            }else{
                let rs={
                pages: 6,
                state:true,
                data:null,
                }   
                this.$emit('done',rs) 
            }  
        },        

        orderChange(checked) {
            this.form.checked = checked
        },   
        
        scoreChange(getscore) {
            this.form.getscore = getscore
        },         


        modifys(type,rs){
            this.iconLoading = true                        
            setTimeout(() => {
                if(type==="update"){
                    this.menber=this.menber.map(x=> { 
                                if(x.uid === this.userid){ 
                                    x.uid = rs.uid                               
                                    x.desc=rs.desc
                                    x.name =rs.name 
                                    x.displayname="",
                                    x.title =rs.title 
                                    x.company =rs.company 
                                    x.finkurl =rs.finkurl     
                                    x.iinkurl =rs.iinkurl       
                                    x.url =rs.url   
                                    x.checked =rs.checked
                                    x.getscore = rs.getscore 
                                }
                                return x;
                                });
                }else{
                    console.log("delete",rs)
                    // 從索引值 1 的地方刪除 1 個元素
                    let id = this.menber.map(x =>x.uid).indexOf(rs.uid);
                    this.menber.splice(id,1)

                } 
                this.decide = false
                this.iconLoading = false
                this.clearn()
                this.$refs['my-loading'].hide()
            }, 1300);              

       
        },     


        beforeUpload(file) {
                this.errors=''
                //this.$emit('upmessage',null)
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                return new Promise((resolve, reject) => {
                  if(!isJpgOrPng) {
                    //this.$message.error('您的檔案必須是 JPG or PNG!');
                    this.errors = '您的檔案必須是 JPG or PNG!'
                    this.mtyps = "error"
                    //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
                  reject(file);
                  } else if(!isLt2M) {
                    //this.$message.error('您的檔案必須是小於 2MB!');
                    this.errors = '您的檔案必須是小於 2MB!'
                    this.mtyps = "error"
                    //this.$emit('upmessage','您的檔案必須是小於 2MB!')
                  reject(file);
                  }else {
                  this.$refs['my-loading'].show()
                  resolve(file);
                  }
              });
        },
        
        async handleChange({file}) {                 
                if(file.status ==='done'){
                    this.form.url = file.response.Location                 
                }
        },          
  
        getdata(){
            return this.form
        },

        catImg(imgs) {
            return require(`../assets/${imgs}`);
        },         
        
        onReset(event) {
                event.preventDefault()
                this.$nextTick(() => {

                })
            },          
        
        onHide(evt) {
            if(evt.trigger === "backdrop"){
                evt.preventDefault();
            }
        }, 
        
        close(){
            this.$emit('close')  
        },
        
        usfetch(params = {}) { 
        this.uloading = true;
        queryuser({
            //results: 4,
            ...params,
        }).then(({ data }) => {
            const pagination = { ...this.upagination };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = data.info.totail;
            //pagination.total = 200;
            this.uloading = false;
            this.udata = data.results;
            this.upagination = pagination; 
        });
        },         

        uhandleTableChange(pagination, filters, sorter) {
        //console.log(pagination);
        const pager = { ...this.upagination };
        pager.current = pagination.current;
        this.upagination = pager;
        //console.log(this.pagination);
        this.usfetch({
            results: pagination.pageSize,
            name: this.name, 
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
        },  
        
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;        
            let rs = this.udata.filter((el)=> { return el.id === selectedRowKeys[0]}); 
            this.$emit('checkstaff',rs,'coach') 
            if(this.merrors==''){
                this.showpanels =!this.showpanels  
            }
        },
        
        
        upsave(){ //存檔變更 FOR 複製
            this.loading = true       
            this.$emit('updage',4,this.uniqueObjArr(this.menber))
        }, 
        updone(){
            this.loading = false
        },
        updone2(){
            this.loading2 = false
        },        
        
        inidata(){
            if(JSON.stringify(this.obj)!=='{}'){ 
                for(var i in this.obj.coachs){
                    this.menber.push(this.obj.coachs[i])
                }                
            }
        },
        async onSearch(value) {
          if(value==""){
            this.name = "all"
          }else{
            this.name = value
          }
          await this.usfetch({results:4,page:1,pageSize: 4,name: this.name});
        },                    

        async onupdate(){           
           const self = this;
           setTimeout(() => {
            if(JSON.stringify(self.cobj)!=='{}'){   
                for(var i in self.cobj.coachs){
                    self.menber.push(self.cobj.coachs[i])
                }         
                    let rs={
                    pages: 6,
                    state:true,
                    data:self.uniqueObjArr(self.menber),
                    }   
                    if(self.cobj.coachs.length>0){
                          self.$emit("done", rs);  
                    }                                 
                } 
            }, "1000");
         },     
    
    
    
    }, 
	mounted () {
        //this.locatorButtonPressed()
        //this.geolocation();
        this.usfetch({results:4,page:1,pageSize: 4,name: this.name});
        this.inidata()

    },
    beforeDestroy() {

    }     

}
</script>
<style scoped>
.card-body{
    padding:5px 0px 5px 0px;
}
.ddiv{
    padding:7px;
    border-radius: 17px;
    margin: 5px auto;
    box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
	transition: all .3s ease;
}
.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}



.bton {
   max-height: 100%;  
   max-width: 100%; 
   width: auto;
   height: 40px;    
   position:relative;
   box-shadow: 0 10px 20px rgba(68, 66, 66, 0.19), 0 6px 6px rgba(0,0,0,0.23);
   left:  0;  
   right: 0;     
}
      div#home a:link { color: #578cc9; }
      div#home a:visited { color: #578cc9; }
      div#home a:hover { color: #578cc9; }
      div#home a:active { color: #578cc9; }

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #302f2f;
}

#style-3{
    text-align: left;
} 

.sava {
  position: fixed;
  right: 0;
  top: 35%;
  width: 8em;
  margin-top: -2.5em;   
}

</style>

