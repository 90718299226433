<template>
    <div>   
            <br>
            <b-card
                header="請設定本次報名活動費用！"
                header-border-variant="light"
                header-bg-variant="light"
                align="center"
                class="card-3"            
                >
               
            <b-card-body style="width:980px;padding:5px 0px 5px 0px;" class="overflow-auto">
            <b-card-text> 
                <a-alert v-if="errors!==''" :message="errors" :type="mtyps" show-icon />                
                <div v-if="JSON.stringify(obj)==='{}'">
                <a-alert v-if="formdata" message="資料填妥, 確認無誤進行下一步" type="success" /> 
                </div>
                <b-form-radio-group
                    v-model="selected"
                    :options="options"
                    @change="changeradio"
                    class="mb-3"
                    value-field="item"
                    text-field="name"
                disabled-field="notEnabled"
                ></b-form-radio-group>    
          
                <div class="text-left" v-if="selected==1" style="padding:5px 50px 5px 50px;">
                <p class="h6"><b-icon-cash-stack></b-icon-cash-stack> 活動費用</p> 
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;"> 
                <p align="right">
                <b-input-group prepend="銀行代碼/帳號" class="mb-2">
                    <b-form-input v-model="bcode" maxlength="3" style="width:150px;" placeholder="請輸入銀行代碼"></b-form-input>
                    <b-form-input v-model="account" maxlength="16" style="width:250px;" placeholder="請輸入銀行帳號"></b-form-input>
                </b-input-group>                    

                <a-button class="bton"  @click="adduser('add')" size="large" type="primary" shape="round">
                <a-icon type="dollar" :style="{ fontSize: '26px' }" ></a-icon>
                    新增費用項目
                </a-button>
                </p> 
                <div style="padding:20px;"></div>
                <div class="text-center">
                    <a-row type="flex" >
                        <a-col :span="4" :offset="1" v-for="data in menber" :key="data.id">
                                <div style="padding:5px;" @click="updatem(data.id)">                                           
                                    <b-avatar :src="data.url" :style="{ cursor: 'pointer'}"  variant="primary" text="data.title" size="7rem" class="align-baseline" square></b-avatar>
                                    <br>
                                    <span  >{{data.fee}} ({{data.title}})</span>
                                </div>                            
                        </a-col>                  
                    </a-row>  
                </div> 
                         
            </div>                            
            </b-card-text>
            </b-card-body>
            </b-card>
            <br>
            <div class="sava" v-if="JSON.stringify(obj)!=='{}'" >  
                 <a-button type="primary" shape="round" icon="save" @click="upsave" size="large" :disabled="!formdata" :loading="loading" block>
                     存檔變更                              
                 </a-button>
            </div>               
        <b-modal ref="my-loading" id="modal-2" title="新增費用項目" @hide="onHide" centered hide-footer>
          <div class="overflow-auto" style="height: 600px;width:100%;" id="style-3">              
            <div class="text-left">  
                <b-form>                       
                    <b-form-group id="input-group-2" style="width:80%" label="項目名稱*  " label-for="input-2">
                        <b-form-input
                            id="input-2"
                            v-model="form.title"
                            type="text"
                            placeholder="輸入項目名稱 "
                            rows="8"
                            autocomplete="off"
                            :state="titleState"                        
                            required
                        ></b-form-input>
                    </b-form-group>      
                    
                    <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">                
                    <b-form-group id="input-group-3" style="width:80%" label="項目費用*" label-for="input-3">
                        <b-form-input
                            id="input-3"
                            v-model="form.fee"
                            type="number"
                            min="0" max="29999"
                            placeholder="輸入項目費用 "
                            autocomplete="off"
                            :state="feeState" 
                            required
                        ></b-form-input>
                    </b-form-group>  

                    <b-form-group id="input-group-2" style="width:98%" label="項目說明*  " label-for="input-2">
                        <b-form-textarea
                            id="input-2"
                            v-model="form.desc"
                            type="text"
                            placeholder="輸入項目說明 "
                            rows="8"
                            autocomplete="off"
                            :state="descState"                        
                            required
                        ></b-form-textarea>
                    </b-form-group>                                                                     
    
                    <p class="h6"><b-icon-tree-fill></b-icon-tree-fill> 上傳檔案</p> 
                    <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;"> 


                    <input type="hidden" name="customfield" class="form-control" :value="form.uid">                 
                </b-form> 
            </div>

            <div class="text-center">                  
                <a-upload
                    name="imgfile"
                    list-type="picture"
                    class="avatar-uploader"
                    method='post'
                    :show-upload-list="false"
                    :headers="headers"
                    :action="uploadurl"
                    :before-upload="beforeUpload"
                    @change="handleChange" 
                >  
                <img class="bimg"  :src="form.url!==''? form.url:catImg('event_1080x540.png')" />
                </a-upload>

            </div>               

          </div>
          <div>
                    <a-button v-if="decide===false" @click="onSubmit" size="large" type="primary" :disabled="checkval" block :loading="iconLoading">新增資料</a-button>
                    <a-button-group v-if="decide">
                    <a-button type="primary" @click="modifys('update', form)" size="large" icon="edit" :disabled="checkval" :loading="iconLoading" >修改</a-button>
                    <a-button type="danger" @click="modifys('delete', form)"  size="large" icon="delete" :loading="iconLoading" >刪除</a-button>
                    </a-button-group>              
          </div>
        </b-modal> 
            
    </div>
</template>

<script>
import {mapState} from 'vuex';

const mcolumns = [
  {
    title: '圖像',
    dataIndex: 'profile',
    width: '25%',    
    scopedSlots: { customRender: 'profile' },    
  },       
  {
    title: '姓名',
    dataIndex: 'username',
    width: '55%',
  },   

];

export default {
    name: 'Mfee',    
    props:{
          page:{
              type:Number
          },
          obj:{ 
              type:Object
          },
          cobj:{ 
            type:Object
          },                      
        },    
    data(){
        return {
            loading:false, 
            errors:'',
            mtyps:'error',
            uploadurl:process.env.VUE_APP_URL +'user/image',  
            menber:[],    
            iconLoading: false,
            decide : false,
            mcolumns,
            udata: [], 
            upagination: {defaultPageSize:4}, 
            uloading: false,    
            selectedRowKeys: [],                   
            form: {
                 id:'',
                 desc : '',            
                 title : '' ,
                 fee : '',
                 url:'',
                },
        selected:'',
        bcode:'',
        account:'',
        options: [
          { item: '0', name: '本次活動無需費用' },
          { item: '1', name: '本次活動需要費用' },
        ]                                                                
        }
    },
    watch: {
        cobj() {
        if (this.cobj !== undefined) {
            if (this.cobj.id > 0) {
            this.onupdate();
            }
        }
        },
    },     
    computed:{
      ...mapState('account',['status','user']),
      headers(){   
        return{                    
          "X-Requested-With":null,
          "Authorization": "Bearer "+this.status.token,
        }
      },
          
      formdata(){ 
        let ch = true
          if(this.bcode=='' || this.account=='' || this.menber.length==0 ){
                ch=false
          }
          if(this.selected!==''){
                ch=true
          }                  
          if(this.menber.length>0){

            let rs={
                pages: 8,
                state:ch,
                data:{
                        fee: this.selected==''? false:true,
                        bcode:this.bcode,
                        account:this.account,
                        item:this.menber
                    },
            }   
            if(JSON.stringify(this.obj)==='{}'){   
                this.$emit('done',rs)               
            }           
            return true
          }else{
            let rs={
                pages: 8,
                state:ch,
                data:{
                        fee:this.selected==''? false:true,
                        bcode:this.bcode,
                        account:this.account,                        
                        item:this.menber
                    },
            }   
            if(JSON.stringify(this.obj)==='{}'){   
                this.$emit('done',rs)            
            }
              return false
          }
      },
      descState() {
        return this.form.desc!=='' ? true : false
      },
        
           
      titleState() {
          return this.form.title!=='' ? true : false
      },

      feeState() {
          return this.form.fee>0 ? true : false
      },

      checkval(){
          if(this.form.title !=='' && this.form.fee>0 && this.form.desc!==''){
              return false
          }
          return true
      } 
        

    }, 
    methods :{
            changeradio(){
                let rs={
                    pages: 8,
                    state:true,
                    data:{
                        fee:false,
                        bcode:this.bcode,
                        account:this.account,                        
                        item:[]
                    },
                }
                if(this.selected==0){
                    this.bcode=''
                    this.account=''
                    this.$emit('done',rs) 
                }else{
                    rs.data.fee=true
                    if(this.menber.length == 0){
                        rs.state=false                        
                    }else{
                        rs.state=true
                        rs.data.item = this.menber
                    }
                    this.$emit('done',rs) 
                }    
            },        
      async onSubmit(event) {
            event.preventDefault()
            this.iconLoading = true                        
            setTimeout(() => {
                let index = Math.floor(Date.now() / 1000)
                let temp ={
                        id: index.toString()+"",
                        desc:this.form.desc,
                        title:this.form.title,
                        fee: parseInt(this.form.fee), 
                        url:this.form.url,
                    }                                       
                this.menber.push(temp)
                this.iconLoading = false
                this.clearn()
                this.$refs['my-loading'].hide()
          }, 1300);            
            
        },

        updatem(id){
             this.decide=true
             this.fillin(this.menber.filter((el)=> { return el.id === id}))
             this.$refs['my-loading'].show()
        },

        clearn(){
            this.form.id =''
            this.form.desc=''
            this.form.title =''
            this.form.fee =0     
            this.form.url =''                           
        },

        fillin(rs){
            this.form.id = rs[0].id
            this.form.desc=rs[0].desc
            this.form.title =rs[0].title 
            this.form.fee = parseInt(rs[0].fee)     
            this.form.url =rs[0].url   
        },

        adduser(type){
            this.clearn()
            this.decide = false
            this.$refs['my-loading'].show()
            console.log(type)
        },


        modifys(type,rs){
            this.iconLoading = true                        
            setTimeout(() => {
                if(type==="update"){
                    this.menber=this.menber.map(x=> { 
                                if(x.id === rs.id){                              
                                    x.desc=rs.desc
                                    x.title =rs.title 
                                    x.fee = parseInt(rs.fee)       
                                    x.url =rs.url   
                                }
                                return x;
                                });
                }else{
                    console.log("delete",rs)
                    // 從索引值 1 的地方刪除 1 個元素
                    let id = this.menber.map(x =>x.id).indexOf(rs.id);
                    this.menber.splice(id,1)

                } 
                this.decide = false
                this.iconLoading = false
                this.clearn()
                this.$refs['my-loading'].hide()
          }, 1300);            

       
        },     


         beforeUpload(file) {
                this.errors=''
                //this.$emit('upmessage',null)
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                //const self = this;
                //this.iconLoading=true
                return new Promise((resolve, reject) => {
                  if(!isJpgOrPng) {
                    //this.$message.error('您的檔案必須是 JPG or PNG!');
                    this.errors = '您的檔案必須是 JPG or PNG!'
                    this.mtyps = "error"
                    //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
                    reject(file);
                  } else if(!isLt2M) {
                    //this.$message.error('您的檔案必須是小於 2MB!');
                    this.errors = '您的檔案必須是小於 2MB!'
                    this.mtyps = "error"
                    //this.$emit('upmessage','您的檔案必須是小於 2MB!')
                    reject(file);
                  }else {
                  this.iconLoading = true
                  this.$refs['my-loading'].show()
                    resolve(file);
                  }
                  //this.iconLoading = false
              });
        },
        
        async handleChange({file}) {                 
                if(file.status ==='done'){
                    this.iconLoading = false
                    this.form.url = file.response.Location                 
                }
        },          
  
        getdata(){
            return this.form
        },

        catImg(imgs) {
            return require(`../assets/${imgs}`);
        },         
        
        onReset(event) {
                event.preventDefault()
                this.$nextTick(() => {

                })
            },          
        
        onHide(evt) {
            if(evt.trigger === "backdrop"){
                evt.preventDefault();
            }
        }, 
        
        close(){
            this.$emit('close')  
        },
                
        upsave(){
            this.loading = true   
            let data={
                    fee:this.selected==0? false:true,
                    bcode:this.bcode,
                    account:this.account,
                    item:this.menber
                }                      
            this.$emit('updage',6,data)
        }, 
        updone(){
            this.loading = false
        },
        
        inidata(){
            if(JSON.stringify(this.obj)!=='{}'){ 
                this.obj.fee==true? this.selected=1:this.selected=0
                this.bcode = this.obj.bcode
                this.account = this.obj.account
                for(var i in this.obj.items){
                    this.menber.push(this.obj.items[i])
                }                
            }
        },

        async onupdate(){            
          const self = this;
          setTimeout(() => {
                if(JSON.stringify(self.cobj)!=='{}'){   
                    self.cobj.fee==true? self.selected=1:self.selected=0
                    self.bcode = self.cobj.bcode
                    self.account = self.cobj.account
                    for(var i in self.cobj.items){
                        self.menber.push(self.cobj.items[i])
                    }                                   
                    let rs={
                        pages: 8,
                        state:true,
                        data:{
                            fee:false,
                            bcode:self.bcode,
                            account:self.account,                        
                            item:[]
                        },
                    } 
                    if(self.cobj.fee>=0){
                          self.$emit("done", rs);  
                    }                                 
                } 
            }, "1000");
        },         
                   
    }, 
	mounted () {
        this.inidata()

    },
    beforeDestroy() {

    }     

}
</script>
<style scoped>
.card-body{
    padding:5px 0px 5px 0px;
}
.ddiv{
    padding:7px;
    border-radius: 17px;
    margin: 5px auto;
    box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
	transition: all .3s ease;
}
.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}



.bton {
   max-height: 100%;  
   max-width: 100%; 
   width: auto;
   height: 40px;    
   position:relative;
   box-shadow: 0 10px 20px rgba(68, 66, 66, 0.19), 0 6px 6px rgba(0,0,0,0.23);
   left:  0;  
   right: 0;     
}
      div#home a:link { color: #578cc9; }
      div#home a:visited { color: #578cc9; }
      div#home a:hover { color: #578cc9; }
      div#home a:active { color: #578cc9; }

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #302f2f;
}

#style-3{
    text-align: left;
} 

.sava {
  position: fixed;
  right: 0;
  top: 35%;
  width: 8em;
  margin-top: -2.5em;   
}

.bimg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 5px 20px rgba(145, 143, 143, 0.19), 0 6px 6px rgba(202, 198, 198, 0.23);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

</style>

